.gpt3__CTA{
    display: flex;
    background-color: orange;
    flex-direction: 'row';
    align-items: center;
    justify-content: space-between;
    /*border:1px solid black;*/
    padding:2rem 4rem;
}

.gpt3__CTA-content{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.gpt3__CTA-content_image{
    width:50px;
    height:50px;
    margin-right: 15px;
}

.gpt3__CTA-content_image img{
    width:100%;
    height: 100%;
}

.gpt3__CTA-content_text{
    /*border:1px solid black;*/
    padding: 15px;
}

.gpt3__CTA-content_text p:first-child{
    font-size: 16px;
    font-weight: 550;
    margin-bottom: 5px;
}

.gpt3__CTA-button button{
    padding:10px 15px;
    background-color: inherit;
    border:2px solid black;
    border-radius: 18px;
}

@media screen and (max-width:750px) {
    .gpt3__CTA{
        display: flex;
        flex-direction: 'column';
        align-items: center;
        justify-content: center;
        padding:1rem 2rem;
    }

}