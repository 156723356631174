.gpt3__projects {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  color: var(--color-black);
  justify-content: center;
  align-items: center;
}

.gpt3__projects-card {
  width: 300px;
  height: 250px;
  box-shadow: 0px 0px 5px #dbd6d652;
  /*padding: 0.5rem 1.5rem;*/
  padding: 1rem 2rem;
  border-radius: 10px;
  border: 1px solid white;
  background-color: black;
  color: white;
}
.gpt3__projects-card:hover {
  color: #0070f3;
  border: 1px solid #0070f3;
}
.gpt3__projects-card:hover span {
  text-decoration: underline;
}
.gpt3__projects-card h1 {
  font-weight: 550;
  font-size: 20px;
}
.gpt3__projects-card h3 {
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  padding-bottom: 0rem;
  text-decoration: underline;
}
.gpt3__projects-card span {
  margin-top: 5px;
}
.gpt3__projects-card_Repolink {
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}
.gpt3__projects-card p {
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
}
@media screen and (max-width: 650px) {
  .gpt3__projects-card {
    width: 300px;
    height: 250px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.322);
    padding: 1rem 2rem;
  }
  .gpt3__projects-card h3 {
    font-weight: 500;
    font-size: 13px;
    line-height: 28px;
  }
}
