.gpt3__navbar {
  /*border-bottom: 1px solid var(--color-black);*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 4rem;
  color: var(--color-black);
}
.gpt3__navbar-brand_logo {
  font-size: 30px;
  line-height: 1.15;
  /*font-size: 4rem;*/
  font-weight: 800;
}
.gpt3__navbar-brand_logo a {
  color: #0070f3;
  text-decoration: none;
}
.gpt3__navbar-brand_logo a:hover {
  text-decoration: underline;
}
.gpt3__navbar-highlights {
  display: flex;
}
.gpt3__navbar-highlights a:hover {
  color: #0070f3;
  font-weight: 600;
}
.gpt3__navbar-highlights p {
  margin-right: 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
}
.gpt3__navbar-contacts {
  display: flex;
}
.gpt3__navbar-contacts a:hover {
  color: #0070f3;
}
.gpt3__navbar-contacts > * {
  margin-right: 10px;
  cursor: pointer;
  margin-right: 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
}
.gpt3__navbar-menu {
  display: none;
}

@media screen and (max-width: 650px) {
  .gpt3__navbar {
    /*border: 1px solid brown;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
  }
  .gpt3__navbar-highlights {
    display: none;
  }
  .gpt3__navbar-contacts {
    display: none;
  }
  .gpt3__navbar-menu {
    display: flex;
    position: relative;
  }
  .gpt3__navbar-menu_container {
    background-color: black;
    color: /*#8ee4af*/ white;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
    z-index: 100;
  }
  .gpt3__navbar-menu_container {
    /*border: 1px solid black;*/
    margin: 4px;
  }
  .gpt3__navbar-menu_highlights {
    padding: 5px;
    /*border: 1px solid black;*/
  }
  .gpt3__navbar-menu_highlights p {
    margin-bottom: 5px;
  }
  .gpt3__navbar-menu_container-contacts {
    display: flex;
    align-items: center;
    margin-top: 10px;
    /*border: 1px solid black;*/
  }
  .gpt3__navbar-menu_container-contacts span {
    padding: 8px;
  }
}
