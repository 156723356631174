.gpt3__welcome{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:2rem 4rem
}

.gpt3__welcome-header{
    padding: 15px 20px;
    margin-bottom: 1rem;
}

.gpt3__welcome-header p{
    font-size: 16px;
    font-weight: 550;
}

.gpt3__welcome-content{
    display: flex;
    justify-content: space-between;
    gap:10px
}
.gpt3__welcome-content_desc{
    flex: 1;
}

.gpt3__welcome-content_desc p{
    line-height:1.5em;
}

.gpt3__welcome-content_desc span{
    font-style: italic;
    font-weight: 550;
}

.gpt3__welcome-content_image{
    flex: 1;
    width:10rem;
    height: 20rem;
}

.gpt3__welcome-content_image img{
    width:100%;
    height: 100%;
}

@media screen and (max-width:600px){
    .gpt3__welcome{
        padding:1rem 2rem
    }
    .gpt3__welcome-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        /*padding:1rem 2rem*/
    }
    .gpt3__welcome-header{
        padding: 10px 20px;
        margin: 0.5rem 0;
    }
    .gpt3__welcome-content_image{
        margin-top: 1rem;
        flex: 1;
        width:100%;
        height: 15rem;
    }
}

