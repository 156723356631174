.gpt3__header {
  display: flex;
  gap: 10px;
  background-image: url('../../assets/fun-kids.jpg');
  background-size: cover;
  background-position: center;
  height: 70vh;
  position: relative;
}
.gpt3__header-content {
  flex: 1;
  color: white;
  position: absolute;
  bottom: 2rem;
  left:3rem
}
.gpt3__header-content h1 {
  font-weight: 800;
  font-size: 4rem;
  /*line-height: 1.15;
  letter-spacing: -0.04em;*/
}
.gpt3__header-content h3 {
  font-size: 45px;
  font-weight: 600;
  padding-right: 5px;
  color:white;
}
.gpt3__header-content p {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-top: 1.5rem;
  color:white;
}
.gpt3__header-content button {
  width: 200px;
  min-height: 50px;
  margin-top: 2rem;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  padding: 0 1rem;
  color: white;
  cursor: pointer;
  outline: none;
  border-radius: 20px;
  background-color: green;
  border: 1px solid green;
}
.gpt3__header-content button:hover {
  border: 2px solid #0070f3;
  background-color: var(--color-black);
  color: white;
}
.gpt3__header-image {
  flex: 0.65;
}
.gpt3__header-image img {
  width: 100%;
}

@media screen and (max-width: 1050px) {
  .gpt3__header {
    flex-direction: column;
  }

  .gpt3__header-content {
    margin: 0 0 2rem;
  }
}
@media screen and (max-width: 650px) {
  .gpt3__header {
    display: flex;
    gap: 10px;
    height: 50vh;
    padding: 1rem 2rem;
  }
  .gpt3__header-content {
    flex: 1;
    color: #ccc;
    position: absolute;
    bottom: 1rem;
    left:2rem
  }
  .gpt3__header-content h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .gpt3__header-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .gpt3__header-content button {
    width: 150px;
    min-height: 50px;
    margin-top: 1rem;
    font-weight: 600;
    font-size: 16px;
    padding: 0 0.5rem;
    cursor: pointer;
    outline: none;
    border-radius: 20px;
  }
  .gpt3__header-content button:hover {
    border: 2px solid #0070f3;
    background-color: var(--color-black);
    color: white;
  }
  .gpt3__header-content__people {
    flex-direction: column;
  }

  .gpt3__header-content__people p {
    margin: 0;
  }

  .gpt3__header-content__input input,
  .gpt3__header-content__input button {
    font-size: 16px;
    line-height: 24px;
    padding: 0 0.5rem;
  }
}

@media screen and (max-width: 490px) {
  .gpt3__header-content {
    margin: 0 0 1rem;
  }
  .gpt3__header-content h1 {
    font-size: 40px;
    line-height: 48px;
  }
  .gpt3__header-content h3 {
    font-size: 25px;
    line-height: 30px;
  }

  .gpt3__header-content p {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  .gpt3__header-content button {
    font-size: 16px;
    line-height: 16px;
    padding: 0 0.5rem;
  }
}
