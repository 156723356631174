.gpt3__footer {
  display: flex;
  flex-direction: column;
  color: var(--color-black);
  background-color: #0a0a0a;
}
.gpt3__footer-heading {
  justify-content: center;
  text-align: center;
  color: white;
  width: 100%;
  padding-top: 5rem;
}
.gpt3__footer-heading h1 {
  font-size: 62px;
  line-height: 75px;
  font-weight: 800;
  letter-spacing: -0.04em;
}
.gpt3__footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 10rem;
}

.gpt3__footer-btn p {
  box-sizing: border-box;
  color: white;
  font-size: 18px;
  line-height: 21px;
  border: 1px solid #0070f3;
  padding: 1rem 2rem;
  cursor: pointer;
  font-weight: 500;
}
.gpt3__footer-btn:hover p {
  border: 2px solid #0070f3;
  font-weight: 650;
}
.gpt3__footer-links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 0rem;
  padding-top: 7px;
  width: 100%;
  background-color: #0a0a0a;
  color: white;
}

.gpt3__footer-links_logo span {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.gpt3__footer-links_logo p {
  margin-top: 1rem;
}
.gpt3__footer-links_logo img {
  width: 118px;
  height: 30px;
  margin-bottom: 1rem;
}
.gpt3__footer-links_div {
  flex-direction: column;
  display: flex;
  margin: 1rem;
  width: 270px;
}
.gpt3__footer-links_div p {
  margin: 1rem 0;
  cursor: pointer;
}
.gpt3__footer-links_div-entry {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.gpt3__footer-copyright {
  text-align: center;
  width: 100%;
  font-size: small;

  padding-top: 3px;
  background-color: #0a0a0a;
  color: white;
  padding-bottom: 2px;
}
.gpt3__footer-copyright p {
  font-size: 12px;
  line-height: 14px;
}

@media screen and (max-width: 850px) {
  .gpt3__footer-heading h1 {
    font-size: 44px;
    line-height: 50px;
  }
}

@media screen and (max-width: 550px) {
  .gpt3__footer {
    margin-top: 5rem;
  }
  .gpt3__footer-heading {
    padding-top: 2rem 2rem;
  }
  .gpt3__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 5rem;
    cursor: pointer;
  }
  .gpt3__footer-links {
    margin-top: 0.5rem;

    /*border: 1px solid brown;*/
  }
  .gpt3__footer-heading h1 {
    font-size: 34px;
    line-height: 42px;
  }

  .gpt3__footer-links div {
    margin: 0.1rem 0;
  }

  .gpt3__footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }
  .gpt3__footer-copyright {
    background-color: #0a0a0a;
    margin-bottom: 2px;
    border-top: 0.01rem solid white;
  }
}
@media screen and (max-width: 400px) {
  .gpt3__footer-heading h1 {
    font-size: 27px;
    line-height: 38px;
  }
}
